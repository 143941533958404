var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "疗程记录",
          "left-text": "",
          "right-text": "",
          "left-arrow": "",
        },
        on: { "click-left": _vm.onClickLeft },
      }),
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "content-box" },
          [
            _c(
              "van-row",
              [
                _c("van-col", { attrs: { span: 24 } }, [
                  _vm._v(
                    "第" +
                      _vm._s(index + 1) +
                      "次 " +
                      _vm._s(item.date) +
                      " " +
                      _vm._s(item.time_name)
                  ),
                ]),
              ],
              1
            ),
            _c(
              "van-row",
              [
                _c("van-col", { attrs: { span: "8" } }, [
                  _vm._v("疗前左视: " + _vm._s(item.bef_eye_left)),
                ]),
                _c("van-col", { attrs: { span: "8" } }, [
                  _vm._v("疗前右视: " + _vm._s(item.bef_eye_right)),
                ]),
              ],
              1
            ),
            _c(
              "van-row",
              [
                _c("van-col", { attrs: { span: "24" } }, [
                  _vm._v("疗前备注: " + _vm._s(item.bef_eye_remark)),
                ]),
              ],
              1
            ),
            _c(
              "van-row",
              [
                _c("van-col", { attrs: { span: "8" } }, [
                  _vm._v("疗后左视: " + _vm._s(item.aft_eye_left)),
                ]),
                _c("van-col", { attrs: { span: "8" } }, [
                  _vm._v("疗后右视: " + _vm._s(item.aft_eye_right)),
                ]),
              ],
              1
            ),
            _c(
              "van-row",
              [
                _c("van-col", { attrs: { span: "24" } }, [
                  _vm._v("疗后备注: " + _vm._s(item.aft_eye_remark)),
                ]),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }